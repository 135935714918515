import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import LayoutWithBottomActions, {
  LayoutWithBottomActionsProps,
} from '../../layouts/LayoutWithBottomActions'
import { StepsProps } from '../../components/Steps'
import Modal, { ModalProps } from '../../components/Modal'
import NoteModal, { NoteModaleProps } from '../../components/NoteModale'
import ModalDelete, { ModalDeleteProps } from '../../components/ModalDelete'
import { NumberedTagProps } from '../../components/NumberedTag'
import ModalFullScreen, { ModalFullScreenProps } from '../../components/ModalFullScreen'
import ModalUpload, { ModalUploadProps } from '../../components/ModalUpload'
import { ActionButtonProps } from '../../components/ActionButton'
import CameraNode, { CameraProps } from '../../components/Camera'
import SimpleCard from '../../components/SimpleCard'
import { selectors } from '../../redux'
import ModalToast, { ModalToastProps } from '../../components/ModalToast'

import * as SC from './styled'

export type LayoutSampleDataProps = LayoutWithBottomActionsProps & {
  title?: string
  subTitle?: string
  steps?: StepsProps
  finalImage?: string
  camera?: {
    cameraModal?: ModalFullScreenProps
    cameraProps?: CameraProps
    openCameraHandler?: () => void
    closeCameraHandler?: () => void
    currentImage?: string
    newImageButton?: ActionButtonProps
    retakeButton?: ActionButtonProps
    uploadButton?: ActionButtonProps
    uploadModal?: ModalUploadProps
  }
  noteModal?: ModalProps
  notes?: NoteModaleProps
  children?: React.ReactNode
  redirectModal?: ModalDeleteProps
  deleteModal?: ModalDeleteProps
  toastModal?: ModalToastProps
  marketplaceWarning?: NumberedTagProps
  onClose?: () => void
  openImage?: boolean
}

const LayoutSampleData: FC<LayoutSampleDataProps> = ({
  title,
  subTitle,
  steps,
  finalImage,
  onClose,
  openImage,
  camera,
  noteModal,
  toastModal,
  notes,
  children,
  redirectModal,
  deleteModal,
  marketplaceWarning,
  ...layoutProps
}) => {
  const sample = useSelector(selectors.samples.sample)

  const simpleCardProps = {
    topInfo: {
      tag: sample?.collection?.exhibitor.name,
      universe: sample?.universe.name,
      pavilion: sample?.collection?.exhibitor?.pavilion.name,
    },
  }

  return (
    <LayoutWithBottomActions
      {...layoutProps}
      modals={
        <>
          {deleteModal && <ModalDelete {...deleteModal} />}
          {toastModal && <ModalToast {...toastModal} />}
          {noteModal && (
            <Modal {...noteModal}>
              <NoteModal {...notes} />
            </Modal>
          )}
          {redirectModal && <ModalDelete {...redirectModal} />}
          {openImage && onClose && (
            <ModalFullScreen isOpen={openImage} onClose={onClose}>
              <SC.ModalPicture>
                <img src={finalImage} alt=" " />
              </SC.ModalPicture>
            </ModalFullScreen>
          )}
          {camera?.cameraModal && camera.cameraProps && (
            <ModalFullScreen {...camera.cameraModal} removeChildrenSpacing>
              <CameraNode {...camera.cameraProps} />
            </ModalFullScreen>
          )}

          {camera?.uploadModal && <ModalUpload {...camera.uploadModal} />}
        </>
      }
    >
      <SC.TopContent>
        {title && <SC.Title tag={'h2'} text={title} />}
        {subTitle && <SC.Title tag={'h5'} text={subTitle} />}
        {marketplaceWarning && <SC.MarketplaceWarning {...marketplaceWarning} />}

        <SimpleCard {...simpleCardProps} />
      </SC.TopContent>
      <SC.Separator />
      <SC.Wrapper>
        {steps ? <SC.StyledSteps {...steps} /> : <SC.Separator />}
        {children && <SC.Content>{children}</SC.Content>}
      </SC.Wrapper>
    </LayoutWithBottomActions>
  )
}

export default LayoutSampleData
