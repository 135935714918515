import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router'
import { isValid } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'

import { useDefaultLayoutTemplateProps } from '../../layouts/DefaultLayout/useProps'
import { ConfigSamplesFormValues } from '../../components/ConfigSamples'
import { returnSampleDataSteps } from '../../relay/SampleEdit/SampleEditSteps'
import { Icons } from '../../components/Icon'
import { router } from '../../router'
import { sampleEditRouting } from '../../relay/SampleEdit/SampleEditRouting'
import { FlagGroup } from '../../graphql/generated/graphql'
import { actions, selectors } from '../../redux'

import { SampleSheetsEditTemplateProps } from '.'

export const useSampleSheetEditTemplateProps = (
  pageTitle?: string,
  pageSubTitle?: string,
  flagGroup?: FlagGroup | undefined
): SampleSheetsEditTemplateProps => {
  const dispatch = useDispatch()
  const layoutProps = useDefaultLayoutTemplateProps('params')
  const flagItemCreate = useSelector(selectors.samples.flagItemCreate)
  const flagItemUpdate = useSelector(selectors.samples.flagItemUpdate)
  const flagItemDelete = useSelector(selectors.samples.flagItemDelete)

  const { routeType } = useParams<{ routeType: string }>()
  const [createMode, setCreateMode] = useState<boolean>(false)
  const [value, setValue] = useState<string | undefined>('')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isModalToastOpen, setIsModalToastOpen] = useState<boolean>(false)
  const [closeFormTrigger, setCloseFormTrigger] = useState(false)

  const ref = useRef<FormikProps<ConfigSamplesFormValues>>(null)

  const navigate = useNavigate()

  const updateFlagItems = useCallback(
    (newitem: string, mirakl: string, itemId?: string) => {
      if (createMode && flagGroup && !itemId) {
        dispatch(
          actions.samples.flagItemCreateRequest({
            flagGroupId: flagGroup?.id,
            name: newitem,
            miraklCategoryLabel: mirakl,
          })
        )
        setCreateMode(false)
      } else {
        if (itemId) {
          dispatch(
            actions.samples.flagItemUpdateRequest({
              flagItemId: itemId,
              name: newitem,
              miraklCategoryLabel: mirakl,
            })
          )
        }
      }
    },
    [createMode, dispatch, flagGroup]
  )

  useEffect(() => {
    if (flagItemCreate && flagItemCreate.success) {
      setIsModalToastOpen(true)
      setTimeout(() => {
        setIsModalToastOpen(false)
        dispatch(actions.samples.resetFlagItemCreate())
      }, 2000)
    }
    if (flagItemUpdate && flagItemUpdate.success) {
      setIsModalToastOpen(true)
      setTimeout(() => {
        setIsModalToastOpen(false)
        dispatch(actions.samples.resetFlagItemUpdate())
      }, 2000)
    }
    if (flagItemDelete && flagItemDelete.success) {
      setIsModalToastOpen(true)
      setCloseFormTrigger(true)
      setTimeout(() => {
        setIsModalToastOpen(false)
        dispatch(actions.samples.resetFlagItemDelete())
      }, 2000)
    }
  }, [
    dispatch,
    flagItemCreate,
    flagItemCreate?.success,
    flagItemDelete,
    flagItemDelete?.success,
    flagItemUpdate,
    flagItemUpdate?.success,
  ])

  const successMessage = useCallback(() => {
    if (flagItemCreate && flagItemCreate.success)
      return `L'option ${value} a bien été ajoutée à la liste.`
    if (flagItemUpdate && flagItemUpdate.success) return `L'option ${value} a bien été modifiée.`
    if (flagItemDelete && flagItemDelete.success)
      return `L'option ${value} a bien été supprimée de la liste.`
    else return 'Success'
  }, [flagItemCreate, flagItemDelete, flagItemUpdate, value])

  return useMemo(
    () => ({
      layoutProps: {
        ...layoutProps,
        steps: {
          steps: returnSampleDataSteps(routeType),
        },
        bottomActions: {
          back: {
            leftIconProps: {
              icon: Icons.arrowLeft,
              width: 18,
              height: 18,
            },
            rounded: true,
            onClick: () => {
              if (routeType) {
                const link = router(sampleEditRouting(routeType, 'prev'), {
                  routeType,
                })
                navigate(link)
              }
            },
          },
          next: {
            text:
              routeType &&
              router(sampleEditRouting(routeType, 'next'), {
                routeType,
              }) === '/parametres/edition-echantillon'
                ? 'Terminer'
                : 'Suivant',
            disabled: !isValid,
            onClick: () => {
              if (routeType) {
                const link = router(sampleEditRouting(routeType, 'next'), {
                  routeType,
                })
                navigate(link)
              }
            },
            rightIconProps: {
              icon:
                routeType &&
                router(sampleEditRouting(routeType, 'next'), {
                  routeType,
                }) === '/parametres/edition-echantillon'
                  ? Icons.check
                  : Icons.arrowRight,
              width: 18,
              height: 18,
            },
          },
        },
        title: 'Paramètres - fiches échantillons',
        subTitle: pageTitle,
        toastModal: {
          text: successMessage(),
          isOpen: isModalToastOpen,
          icon: { icon: Icons.check, height: 20, width: 20 },
        },
      },
      title: pageSubTitle,
      configSamples: {
        deleteTrigger: closeFormTrigger,
        setDeleteTrigger: () => setCloseFormTrigger(false),
        addButton: {
          text: 'Ajouter une option',
        },
        deleteButton: {
          text: 'Supprimer',
          onClick: () => setIsModalOpen(true),
        },
        setCreateMode: () => setCreateMode(true),
        formikForm: {
          innerRef: ref,
          initialValues: {
            name: '',
            id: '',
            mirakl: '',
          } as ConfigSamplesFormValues,
          validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            mirakl: Yup.string().required(),
          }),
          onSubmit: (values: ConfigSamplesFormValues) => {
            setValue(values.name)
            updateFlagItems(values.name, values.mirakl, values.id !== '' ? values.id : undefined)
          },
          validateOnChange: false,
        },
        items: flagGroup?.items
          ?.map((flagItem) => ({
            name: flagItem.name,
            id: flagItem.id,
            mirakl: flagItem.miraklCategoryLabel as string,
          }))
          .sort((a, b) => {
            const nameA = a.name.toLowerCase()
            const nameB = b.name.toLowerCase()

            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          }),
        submitAddForm() {
          ref.current && ref.current.submitForm()
        },
      },
      deleteModal: {
        isOpen: isModalOpen,
        onClose: () => setIsModalOpen(false),
        text: 'Êtes-vous sûr de vouloir supprimer cette option ?',
        cancelButton: {
          text: 'Annuler',
          onClick: () => setIsModalOpen(false),
        },
        deleteButton: {
          text: 'Oui, supprimer',
          onClick: () => {
            setIsModalOpen(false)
            setCreateMode(false)
            setValue(ref.current?.values.name)
            ref.current?.values.id &&
              dispatch(
                actions.samples.flagItemDeleteRequest({ flagItemId: ref.current?.values.id })
              )
          },
        },
      },
    }),
    [
      closeFormTrigger,
      dispatch,
      flagGroup?.items,
      isModalOpen,
      isModalToastOpen,
      layoutProps,
      navigate,
      pageSubTitle,
      pageTitle,
      routeType,
      successMessage,
      updateFlagItems,
    ]
  )
}
