import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Field, FormikProps } from 'formik'

import { ActionButtonProps } from '../ActionButton'
import { Icons } from '../Icon'
import { ActionButtonScheme, ActionButtonSize } from '../ActionButton/types'
import { FormikFormProps } from '../form/FormikForm'
import { FormErrorsProps } from '../form/FormErrors'

import * as SC from './styled'

export type ConfigSamplesFormValues = {
  name: string
  id: string
  mirakl: string
}

export type ConfigSamplesProps = {
  className?: string
  addButton?: ActionButtonProps
  deleteButton?: ActionButtonProps
  deleteCallback?: (id: string) => void
  items?: { name?: string; id?: string; mirakl: string | null }[]
  formikForm?: FormikFormProps<ConfigSamplesFormValues>
  errors?: FormErrorsProps['errors']
  setCreateMode?: () => void
  submitAddForm?: () => void
  deleteTrigger: boolean
  setDeleteTrigger: (value: boolean) => void
}

const ConfigSamples: FC<ConfigSamplesProps> = ({
  className,
  items,
  formikForm,
  addButton,
  deleteButton,
  setCreateMode,
  submitAddForm,
  deleteTrigger,
  setDeleteTrigger,
  errors,
}) => {
  const [currentEditable, setCurrentEditable] = useState(-1)
  const [add, setAdd] = useState(false)
  const formRef = useRef<FormikProps<ConfigSamplesFormValues> | null>(null)

  const openAddForm = useCallback(() => {
    setCurrentEditable(-1)
    setCreateMode && setCreateMode()
    setAdd(true)
  }, [setCreateMode])

  const closeAddForm = useCallback(() => {
    setDeleteTrigger(false)
    setCurrentEditable(-1)
    setAdd(false)
  }, [setDeleteTrigger])

  const openEditForm = useCallback((i: number) => {
    setCurrentEditable(i)
    setAdd(false)
  }, [])

  useEffect(() => {
    if (deleteTrigger) closeAddForm()
  }, [closeAddForm, deleteTrigger])

  const renderForm = useCallback(
    (mirakl?: string | null, name?: string, id?: string, index?: number) => {
      return formikForm ? (
        <SC.Form
          innerRef={formRef}
          {...formikForm}
          initialValues={{
            ...formikForm.initialValues,
            ...(name && { name: name }),
            ...(id && { id: id }),
            ...(mirakl && { mirakl: mirakl }),
          }}
        >
          <SC.FormBlock>
            <Field type="text" name="name" />
            {typeof index !== undefined && <Field type="hidden" name="index" value={index} />}
            <SC.FormButtons>
              <SC.FormButton
                type="button"
                onClick={() => {
                  closeAddForm()
                  submitAddForm && submitAddForm()
                }}
              >
                <SC.FormIcon icon={Icons.check} width={22} height={22} />
              </SC.FormButton>
              <SC.FormButton type="reset" onClick={closeAddForm}>
                <SC.FormIcon icon={Icons.close} width={22} height={22} />
              </SC.FormButton>
            </SC.FormButtons>
          </SC.FormBlock>
          <SC.FormBlockMirakl>
            <SC.MiraklTitle>Libellé Mirakl :</SC.MiraklTitle>{' '}
            <SC.StyledField type="text" name="mirakl" />
          </SC.FormBlockMirakl>
          {id && deleteButton && (
            <SC.FormBlock>
              <SC.DeleteButton
                type="button"
                variantSize={ActionButtonSize.Small}
                rightIconProps={{ icon: Icons.trash, height: 12, width: 12 }}
                {...deleteButton}
              />
            </SC.FormBlock>
          )}
          {errors && <SC.Errors errors={errors} />}
        </SC.Form>
      ) : null
    },
    [closeAddForm, deleteButton, errors, formikForm, submitAddForm]
  )

  return (
    <SC.Container className={className}>
      {addButton && (
        <SC.Buttons>
          <SC.AddButton
            rightIconProps={{ icon: Icons.plus, width: 24, height: 24 }}
            variantSize={ActionButtonSize.Large}
            variantScheme={ActionButtonScheme.Secondary}
            type="button"
            onClick={(e) => {
              addButton.onClick?.(e)
              openAddForm()
            }}
            {...addButton}
          />
        </SC.Buttons>
      )}
      <SC.List>
        {add && formikForm && renderForm()}
        {items &&
          items.length > 0 &&
          items.map((item, index) => (
            <li key={`ConfigSample-${index}`}>
              {index === currentEditable && formikForm ? (
                renderForm(item.mirakl, item.name, item.id, index)
              ) : (
                <SC.Item onClick={() => openEditForm(index)} type="button">
                  <span>{item.name}</span>
                  <SC.ItemIcon icon={Icons.pencil} width={18} height={18} />
                </SC.Item>
              )}
            </li>
          ))}
      </SC.List>
    </SC.Container>
  )
}

export default ConfigSamples
